import React from 'react'
import { CgWebsite } from "react-icons/cg";
import { FaCode } from "react-icons/fa";
import { IoBookSharp } from "react-icons/io5";
import { FaBlogger } from "react-icons/fa";

const Button = ({darkTheme}) => {
  return (
    <div className='max-w-sm mx-auto grid justify-center gap-3 my-4 font-bold'>
      <a href="https://woilo.com/user/Rezaeza._" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-slate-800 hover:bg-slate-700 active:bg-slate-900' : 'text-slate-900 bg-white hover:bg-slate-100 active:bg-slate-200'}`}> 
      AKUN Woilo 🙏
      </a>
      <a href="https://rezaaezaa.my.id/" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-slate-800 hover:bg-slate-700 active:bg-slate-900' : 'text-slate-900 bg-white hover:bg-slate-100 active:bg-slate-200'}`}> 
      Website Official 🧑‍💻
      </a>
    </div>
  )
}
export default Button
